import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import '../style/montserrat.scss'
import music from '../assets/music/ninda.mp3'
import logoig from '../assets/img/dinny/logoig.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import pathputih1 from '../assets/img/ninda/pathputih1.svg'
import pathblue1 from '../assets/img/ninda/pathblue1.svg'
import covid from '../assets/img/ninda/covid.svg'
import styled from 'styled-components'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import post from "../params/post"
let cmain = '#5e7561'
let orange = '#B99225'
let black = 'rgb(38,38,38)'

let id = 'devi-eko'
let inisial_co = 'Eko'
let inisial_ce = 'Devi'
let lengkap_co = 'Stefanus Eko Setiyono'
let lengkap_ce = 'Kezia Devianti Simanungkalit'
let bapak_co = 'Bpk. Vincentius Harry S'
let ibu_co = 'Ibu Tan Heniy'
let bapak_ce = "Bpk. Saur Dongan Simanungkalit "
let ibu_ce = "Ibu Erna Marisi Tobing"
let ig_co = "-"
let ig_ce = ""

let foto_ce = "https://i.ibb.co/WHrgx9M/Individu-2.jpg"
let foto_co = "https://i.ibb.co/wRYZWc4/Individu-1.jpg"
let waktunikah = "02/24/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://g.page/javavillageresort?share"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MWQxdWZqdXAzZTY1MDFxcGpyaDNzc2M4azkgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ["20201209095907_IMG_6984.jpg",
    "20201209102733_IMG_7032-1.jpg",
    "20201209104715_IMG_7058.jpg",
    "20201209104720_IMG_7059.JPG",
    "DSC_0250.jpg",
    "DSC_0328.jpg",
    "DSC_0345.jpg",
    "IMG_20200806_175856.jpg",
    "IMG_20200906_182445.jpg",
    "IMG_20210113_095246.jpg",
]
let blue = '#5e7561'

const Containers = styled.div`
  @media(min-width:900px){
      background-image:url('${pw(id, "modal-pc.jpg")}')!important
  }
  background-image:url('${pw(id, "modal.jpg")}')!important
`;
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date(waktunikah).getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })


        }, 1000);

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        let s = this.useQuery().get('s');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5'>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Containers id='g3-header' className='container-fluid position-relative' style={{

                        }}>
                            <div className="position-absolute" style={{ left: 0, bottom: '10%', width: '100vw' }}>
                                <Item>
                                    <Col xs={12} md={4} className='m-2 m-md-0 '>
                                        <img className='img-fluid w-100 p-2'
                                            src={gambar(openlogo)} data-aos="fade-left" data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    {
                                        <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`}
                                            style={{ marginTop: '0' }} data-aos="fade-right">
                                            Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                    }</Item>
                                <Row className='justify-content-center'>
                                    <div onClick={() => { this.play() }}
                                        // data-aos="fade-left"
                                        className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                        style={{ marginTop: 0, color: 'white' }}>
                                        Open Invitation
                            </div>
                                </Row>

                            </div>
                            <img className="w-100 img-fluid position-absolute" style={{ left: 0, bottom: 0 }}
                                src={pathblue1}
                            />

                        </Containers>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'white' }}>
                                <Container fluid style={{
                                    backgroundColor: blue
                                }}>
                                    <Container className="dinny px-3 py-5 " >
                                        <Item>

                                            <img src={'https://i.ibb.co/9gFs70g/Bird.png'} style={{ width: '50px', height: '50px' }} />

                                            <p className="text-center  px-3 py-3 w-100 text-center"
                                                style={{
                                                    color: 'white',
                                                    fontFamily: 'Montserrat,Helvetica,Arial,Lucida,sans-serif',
                                                    fontSize: '14px'

                                                }} data-aos="fade-left">
                                                Matius 19:6 Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia."
                                        </p>
                                        </Item>

                                    </Container>
                                </Container>
                                <Container fluid style={{ backgroundColor: blue, padding: 0, position: 'relative' }}>
                                    <img src={pathputih1} className="position-absolute"
                                        style={{ bottom: 0, left: 0, width: '110vw' }} />
                                </Container>
                                <Container style={{ color: 'black' }} className="py-5">
                                    <Item>
                                        <Col md={5}>
                                            <h1 className="w-100 text-left"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: blue,
                                                    fontSize: '36px'
                                                }} data-aos="fade-left">
                                                {lengkap_ce}
                                            </h1>
                                            <img src={logoig} className='btn p-0 my-3'
                                                data-aos="fade-right"
                                                onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                            <p className="w-100 text-left" style={{ fontSize: '16px' }}>
                                                <b>Putri dari: </b><br />
                                                {bapak_ce}<br />
                                                & {ibu_ce}
                                            </p>
                                        </Col>
                                        <Col md={2} >
                                            <Row className="h-100">
                                                <h1 className="w-100 text-center my-auto"
                                                    style={{
                                                        fontFamily: 'Parisienne, cursive',
                                                        color: blue, fontSize: '72px'
                                                    }} data-aos="fade-left">
                                                    &
                                            </h1>
                                            </Row>
                                        </Col>
                                        <Col md={5}>
                                            <h1 className="w-100 text-right"
                                                data-aos="fade-right"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive', color: blue,
                                                    fontSize: '36px'
                                                }}>
                                                {lengkap_co}
                                            </h1>

                                            <div className="text-right">


                                                <img src={logoig} className='btn p-0 my-3'
                                                    onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                            </div>
                                            <p className="w-100 text-right" data-aos="fade-left"
                                                style={{ fontSize: '16px' }}>
                                                <b>Putra dari : </b><br />{bapak_co}<br />
                                                & {ibu_co}
                                            </p>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container fluid className="text-center px-4 dinny py-3"
                                    style={{ color: 'black' }} >
                                    <Item>
                                        <p style={{ fontSize: '16px' }} data-aos="zoom-in">
                                            Yang akan dilaksanakan pada:
                                        </p>
                                    </Item>

                                    <Item>
                                        <Col md={5}>
                                            <p className="w-100 text-center" style={{ color: 'black', fontSize: '18px' }} data-aos="fade-right">
                                                <b style={{ color: orange, fontSize: '36px', fontFamily: 'Sacramento, cursive' }}>
                                                    <img src={'https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg'}
                                                        width="50px" height="50px" /><br />Pemberkatan </b><br />
                                                <span style={{ fontSize: '16px' }}>
                                                    Rabu, 24 Februari 2021<br />
                                                    10.00-10.30 WIB
                                             </span>

                                            </p>
                                        </Col>
                                        <Col md={5} className="pt-3 pt-md-0">
                                            <p className=" w-100 text-center" style={{ color: 'black', fontSize: '18px' }} data-aos="fade-right">
                                                <b style={{ color: orange, fontSize: '36px', fontFamily: 'Sacramento, cursive' }}
                                                >
                                                    <img src={'https://www.flaticon.com/svg/static/icons/svg/703/703213.svg'}
                                                        width="50px" height="50px" /><br />
                                                    Resepsi</b><br />
                                                <span style={{ fontSize: '16px' }}>
                                                    Rabu, 24 Februari 2021<br />
                                                    {s == 'zxc' ? "11.00-12.00 WIB" : ""}
                                                    {s == 'cxz' ? "12.20-13.20 WIB" : ""}
                                                    {s == 'qwe' ? "13.35- 14.35 WIB" : ""}
                                                </span>
                                            </p>
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px' }} data-aos="fade-left">

                                            <span >
                                           <b> Resto Taman Hek
                                               </b><br/>
                                               Jalan Raya Bogor Km 21 No. 17, RT.6/RW.5, Rambutan, Ciracas, RT.9/RW.5, RT.9/RW.5, Rambutan, Kec. Ciracas, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13830
                                        </span>
                                        </p>
                                    </Item>

                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${blue}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open('https://maps.app.goo.gl/Ro64QTSo6fGgYaiL9')
                                            }}
                                            data-aos="fade-right"
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: blue }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=NDRmN29zYXYxM2g2cDZucTVlODRlcDY1NXAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${blue}`,
                                                borderRadius: '10px'
                                            }}
                                            data-aos="fade-left"

                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: blue }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>

                                <Container id='sectiongold57'>
                                    <h1 className="w-100 text-center" style={{
                                        fontFamily: '"Marck Script", cursive',
                                        color: cmain,
                                        fontSize: '36px'
                                    }}>
                                        Save The Date
                                    </h1>
                                    <div data-aos="fade-right">

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak'
                                                style={{ backgroundColor: cmain }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                                                        </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                                                    </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                                                    </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>
                                {/* <Container className="text-center py-5 dinny" id="dinny">
                                    <>
                                        <Item>
                                            <h1 style={{
                                                fontSize: '72px',
                                                fontFamily: "'Marck Script', cursive",
                                                color: cmain
                                            }}>
                                                Rundown
                          </h1>
                                        </Item>

                                        <Item>
                                            <Col sm={2} xs={4}>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" className="img-fluid w-100 p-2" />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <p className="cblack fs16">
                                                <b>
                                                    08.00 - 10.00
                        </b><br />
                        Akad Nikah
                      </p>
                                        </Item>
                                        <Item>
                                            <Col sm={4} className="pt-sm-3">
                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>
                                                <div className="d-block opa">
                                                    <Item>
                                                        <Col xs={3} sm={4} >
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8}>
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                              Photo Session
                            </p>
                                                        </Col>
                                                    </Item>
                                                </div>

                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>


                                            </Col>


                                            <Col sm={4} className="pt-sm-3">

                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>
                                                <div>

                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={4} xs={3}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8} >
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                             Family Photo Session
                            </p>
                                                        </Col>

                                                    </Item>
                                                </div>
                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>

                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>





                                            </Col>
                                        </Item>
                                    </>
                                </Container> */}


                                {/* <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: '#B99225'
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                        </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                      </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container> */}


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} data-aos="fade-right" />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3' data-aos="fade-left">

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 ' style={{ fontSize: '16px' }}>
                                                            "nikahilah sahabatmu”. Nikahilah orang yang asik untuk diajak berbicara dan nyambung dalam berinteraksi antara satu sama lain. Karena sahabat terbaik, akan menjadi istri terbaik bagimu. Maka “bersahabatlah”. Karena dasar pernikahan yang baik adalah pernikahan yang didasari dengan nilai persahabatan yang baik.
<br />
                                                            <i>Friedrich Nietzche</i>
                                                        </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container fluid style={{ backgroundColor: blue }} className="pb-3">
                                    <Container id='sectiongold58' >

                                        <div className='pt-3 mt-4 mt-lg-5 mb-lg-3' data-aos="fade-right">
                                            <Item>
                                                <Col xs={4} lg={2}>
                                                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                                </Col>
                                            </Item>
                                            <Item>
                                                <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                    <Item>
                                                        <h1 className="w-100 text-center" style={{
                                                            fontFamily: '"Marck Script", cursive',
                                                            color: cmain
                                                        }}>
                                                            Send Your Wishes
</h1>
                                                    </Item>
                                                    <Item>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </Container>

                                </Container>
                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

